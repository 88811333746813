import { memo } from 'react';
import { AccountType, EMPTY_COACH_AVATAR_SRC } from 'constants/user';
import CloseIcon from 'svg/CloseIcon';
import { formatDate } from 'screens/TournamentDetails/utils';
import { ButtonText } from 'components/Button';
import Modal from 'components/modals/Modal';
import ReflectionCard from './ReflectionCard';
import { ReflectionDetailModalProps } from './props';

const ReflectionCardMemoised = memo(ReflectionCard);

const ReflectionDetailModal: React.FC<ReflectionDetailModalProps> = ({
  isOpen,
  userType,
  handleClose,
  reflectionInfo,
}) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} classNameMaxWidth="max-w-[80%] w-full">
      <div className="flex items-center justify-between border-b px-8 py-4">
        <span className="typography-product-heading-desktop">
          {reflectionInfo?.date && formatDate({ date: reflectionInfo?.date })}
        </span>
        <ButtonText onClick={handleClose}>
          <CloseIcon className="h-5 w-5" />
        </ButtonText>
      </div>
      <div className="mx-auto max-w-main-content-container px-8 py-6">
        {userType === AccountType.Player && (
          <div className="mb-8 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            <h2 className="typography-product-heading-compact-desktop mb-3 ">Coach</h2>
            <div className="flex items-center gap-2">
              <img
                src={reflectionInfo?.avatarInfo?.imageSrc || EMPTY_COACH_AVATAR_SRC}
                className="h-8 w-8 rounded-full"
              />
              <span className="typography-product-caption">{reflectionInfo?.avatarInfo?.name}</span>
            </div>
          </div>
        )}
        <h2 className="typography-product-heading-compact-desktop mb-8 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
          Skills Trained
        </h2>
        <div className=" flex flex-col gap-12">
          {reflectionInfo?.reflectionDetails?.map((reflectionDetail, index) => (
            <ReflectionCardMemoised
              key={index}
              skills={reflectionDetail.skill}
              detail={reflectionDetail.detail}
              media={reflectionDetail.media}
              sentiment={reflectionDetail.sentiment}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ReflectionDetailModal;
