export default function AlertIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={23} height={21} fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.06 2.337c1.07-1.903 3.81-1.903 4.88 0l7.813 13.89c1.05 1.866-.299 4.172-2.44 4.172H3.688c-2.142 0-3.49-2.306-2.44-4.173L9.06 2.337ZM12.9 16.2a1.4 1.4 0 1 1-2.8 0 1.4 1.4 0 0 1 2.8 0ZM11.5 5a1.4 1.4 0 0 0-1.4 1.4v4.2a1.4 1.4 0 0 0 2.8 0V6.4A1.4 1.4 0 0 0 11.5 5Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
