export default function ArrowNarrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.707 10.293a.999.999 0 0 1 0 1.414l-4.005 4a1.001 1.001 0 0 1-1.416 0l-4.005-4a1 1 0 0 1 .712-1.695c.262-.002.515.099.704.281l2.296 2.293V5a1 1 0 0 1 1-1 1.002 1.002 0 0 1 1.002 1v7.586l2.296-2.293a1.002 1.002 0 0 1 1.416 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
