const NoteIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill="currentColor"
      d="m11.353 7.035-3.58 3.58a1.63 1.63 0 0 0-.428.755l-.278 1.11a.66.66 0 0 0 .8.8l1.11-.277c.286-.072.547-.22.756-.429l3.58-3.58a1.387 1.387 0 0 0-1.96-1.96M4.822 1.07a.37.37 0 1 0-.74 0v.37h-.371A1.112 1.112 0 0 0 2.6 2.552v8.894a1.112 1.112 0 0 0 1.112 1.112h1.896c.005-.085.018-.17.04-.257l.278-1.11c.104-.417.32-.798.623-1.101l4.28-4.28a2.15 2.15 0 0 1 .665-.45V2.551a1.112 1.112 0 0 0-1.112-1.112h-.37v-.37a.37.37 0 0 0-.742 0v.37H7.418v-.37a.37.37 0 1 0-.742 0v.37H4.823v-.37Zm.371 3.335H8.9a.37.37 0 1 1 0 .741H5.194a.37.37 0 1 1 0-.741ZM7.17 6.999a.37.37 0 0 1-.37.37H5.194a.37.37 0 0 1 0-.74H6.8a.37.37 0 0 1 .37.37ZM5.194 8.852h.082a.37.37 0 0 1 0 .741h-.082a.37.37 0 1 1 0-.741Z"
    />
  </svg>
);

export default NoteIcon;
