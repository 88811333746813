import { useMemo } from 'react';
import { RemoteFeedbackTableProps } from 'screens/CoachPlayersTab/props';
import { mapVideoAnalysisToTable } from 'screens/CoachPlayersTab/utils';
import ExpandableTable from 'components/ExpandableTable';

const RemoteFeedbackTable: React.FC<RemoteFeedbackTableProps> = ({ videos, className }) => {
  const headers = [
    { label: '#' },
    { label: 'Skill', headerWidthClassName: 'w-[60%]' },
    { label: 'Activities' },
  ];

  const rows = useMemo(() => mapVideoAnalysisToTable(videos), [videos]);

  return (
    <div className={className}>
      <h4 className="typography-product-heading-compact-desktop mb-4 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
        Skills analyzed
      </h4>
      <ExpandableTable
        labelClassName="typography-product-chips-filters text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary"
        rows={rows}
        headers={headers}
        expandedRowClassName="text-color-text-brand"
      />
    </div>
  );
};

export default RemoteFeedbackTable;
