import classNames from 'styles/utils/classNames';
import { ConfidenceTabs } from './enums';
import { ConfidenceSwitcherProps } from './props';

const ConfidenceSwitcher: React.FC<ConfidenceSwitcherProps> = ({
  activeTab,
  setActiveTab,
  className,
}) => {
  const shadowClass = 'shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10),0px_1px_2px_0px_rgba(0,0,0,0.06)]';

  return (
    <div
      className={classNames(
        'relative mx-auto flex h-10 w-full flex-col items-center justify-center whitespace-nowrap rounded-3xl bg-color-bg-lightmode-secondary p-0.5 py-1  dark:bg-color-bg-darkmode-secondary',
        className,
      )}
    >
      <div className="relative flex w-full flex-1 justify-center">
        <div
          className={`absolute bottom-0 left-0 top-0 w-1/2 rounded-3xl transition-transform duration-300 ${shadowClass} ${
            activeTab === ConfidenceTabs.Recent ? 'translate-x-0' : 'translate-x-full'
          } bg-color-bg-lightmode-primary dark:bg-color-bg-darkmode-primary`}
        ></div>
        <button
          onClick={() => setActiveTab(ConfidenceTabs.Recent)}
          className={`z-10 flex-1 shrink self-stretch overflow-hidden rounded-3xl px-6 ${
            activeTab === ConfidenceTabs.Recent
              ? 'text-color-text-lightmode-primary dark:text-color-text-darkmode-primary'
              : 'text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary'
          }`}
        >
          Most Recent
        </button>
        <button
          onClick={() => setActiveTab(ConfidenceTabs.General)}
          className={`z-10 flex-1 shrink self-stretch overflow-hidden rounded-3xl px-6 ${
            activeTab === ConfidenceTabs.General
              ? 'text-color-text-lightmode-primary dark:text-color-text-darkmode-primary'
              : 'text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary'
          }`}
        >
          General
        </button>
      </div>
    </div>
  );
};

export default ConfidenceSwitcher;
