import { Fragment, useEffect, useState } from 'react';
import ChevronUp from 'svg/ChevronUp';
import classNames from 'styles/utils/classNames';

const ExpandableTable: React.FC<ExpandableTableProps> = ({
  headers,
  rows,
  labelClassName,
  onRowExpand,
  expandedRowClassName,
}) => {
  const [expandedRows, setExpandedRows] = useState<Set<number | string>>(new Set());

  const borderClass = 'border-color-border-card-lightmode  dark:border-color-border-card-darkmode ';

  const handleRowClick = (id: string | number) => {
    setExpandedRows((prevRows) => {
      prevRows.has(id) ? prevRows.delete(id) : prevRows.add(id);
      return new Set(prevRows);
    });
  };

  const renderArrow = (isExpanded: boolean) => {
    return (
      <ChevronUp
        className={classNames(
          'ml-auto h-5 w-5 transition-transform duration-300',
          !isExpanded && 'rotate-180',
        )}
      />
    );
  };

  useEffect(() => {
    onRowExpand?.([...expandedRows.values()]);
  }, [expandedRows]);

  return (
    <div className={classNames('overflow-hidden rounded-[1.5rem] border', borderClass)}>
      <table className="min-w-full">
        <thead className="bg-color-bg-lightmode-tertiary dark:bg-color-bg-darkmode-tertiary">
          <tr className="text-left">
            {headers.map((header, index) => (
              <th
                key={index}
                className={classNames('px-4 py-2', labelClassName, header?.headerWidthClassName)}
              >
                {header.label}
              </th>
            ))}
            <th className="w-2 p-2" />
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <Fragment key={row.id}>
              <tr
                className="cursor-pointer border-t border-color-border-card-lightmode dark:border-color-border-card-darkmode"
                onClick={() => (row.expandableContent ? handleRowClick(row.id) : null)}
              >
                {row.rowData.map((cell, index) => (
                  <td
                    key={index}
                    className={classNames(
                      'px-4 py-2',
                      expandedRowClassName && expandedRows.has(row.id) && expandedRowClassName,
                    )}
                  >
                    {cell}
                  </td>
                ))}
                {/* Arrow cell */}
                <td className="p-2">
                  {row.expandableContent && renderArrow(expandedRows.has(row.id))}
                </td>
              </tr>

              {expandedRows.has(row.id) && row.expandableContent && (
                <tr className="border-t border-color-border-card-lightmode dark:border-color-border-card-darkmode">
                  <td colSpan={headers.length + 1}>
                    <div className="p-4">{row.expandableContent}</div>
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExpandableTable;
