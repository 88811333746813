import * as React from 'react';

export default function VideoCamera({ className, viewBox }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox={viewBox || '0 0 24 24'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Video camera">
        <g id="Icon">
          <path
            d="M2.3999 7.19999C2.3999 5.8745 3.47442 4.79999 4.7999 4.79999H11.9999C13.3254 4.79999 14.3999 5.8745 14.3999 7.19999V16.8C14.3999 18.1255 13.3254 19.2 11.9999 19.2H4.7999C3.47442 19.2 2.3999 18.1255 2.3999 16.8V7.19999Z"
            fill="currentColor"
          />
          <path
            d="M17.4632 8.52668C17.0567 8.72995 16.7999 9.14546 16.7999 9.59999V14.4C16.7999 14.8545 17.0567 15.27 17.4632 15.4733L19.8632 16.6733C20.2352 16.8593 20.677 16.8394 21.0308 16.6208C21.3846 16.4021 21.5999 16.0159 21.5999 15.6V8.39999C21.5999 7.9841 21.3846 7.59786 21.0308 7.37921C20.677 7.16056 20.2352 7.14068 19.8632 7.32668L17.4632 8.52668Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
