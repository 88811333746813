import { Tabs } from './enums';
import { SwitcherProps } from './props';

const TabSwitcher: React.FC<SwitcherProps> = ({ activeTab, setActiveTab }) => {
  const shadowClass = 'shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10),0px_1px_2px_0px_rgba(0,0,0,0.06)]';

  return (
    <div className="relative mx-auto flex h-10 w-full flex-col items-center justify-center whitespace-nowrap rounded-3xl bg-color-bg-lightmode-secondary p-0.5 py-1  dark:bg-color-bg-darkmode-secondary">
      <div className="relative flex w-full flex-1 justify-center">
        <div
          className={`absolute bottom-0 left-0 top-0 w-1/2 rounded-3xl transition-transform duration-300 ${shadowClass} ${
            activeTab === Tabs.OnCourt ? 'translate-x-0' : 'translate-x-full'
          } bg-color-bg-lightmode-primary dark:bg-color-bg-darkmode-primary`}
        ></div>

        <button
          onClick={() => setActiveTab(Tabs.OnCourt)}
          className={`z-10 flex-1 shrink self-stretch overflow-hidden rounded-3xl px-6 ${
            activeTab === Tabs.OnCourt
              ? 'text-color-text-lightmode-primary dark:text-color-text-darkmode-primary'
              : 'text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary'
          }`}
        >
          On-court
        </button>
        <button
          onClick={() => setActiveTab(Tabs.Remote)}
          className={`z-10 flex-1 shrink self-stretch overflow-hidden rounded-3xl px-6 ${
            activeTab === Tabs.Remote
              ? 'text-color-text-lightmode-primary dark:text-color-text-darkmode-primary'
              : 'text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary'
          }`}
        >
          Remote
        </button>
      </div>
    </div>
  );
};

export default TabSwitcher;
