import Pill from 'components/Pill';
import { ConfidentCardProps } from './props';
import { getSkillLabel } from './utils';

const ConfidentCard: React.FC<ConfidentCardProps> = ({ confident = false, skills }) => {
  return (
    <div className="flex flex-col gap-4 rounded-[1.5rem] border border-color-border-card-lightmode p-4 dark:border-color-border-card-darkmode">
      <img
        className="h-14 w-14"
        src={`/images/coaches/${confident ? 'positive' : 'negative'}-feedback.png`}
        alt="feedback-avatar"
      />
      <div className="flex flex-wrap gap-2">
        {skills.map((skill, key) => (
          <Pill text={getSkillLabel(skill)} key={key} />
        ))}
      </div>
    </div>
  );
};

export default ConfidentCard;
