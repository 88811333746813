import { Tooltip } from 'react-tooltip';
import { EMPTY_AVATAR_SRC } from 'constants/user';
import { FileFormatEnum } from 'types/generated/client';
import AlertIcon from 'svg/AlertIcon';
import ArrowRight from 'svg/ArrowRight';
import Check from 'svg/Check';
import NoteIcon from 'svg/NoteIcon';
import Pencil from 'svg/Pencil';
import { ReflectionTableProps } from 'screens/CoachPlayersTab/props';
import { getDifferenceInHours } from 'screens/CoachPlayersTab/utils';
import Button from 'components/Button';
import CustomTable from 'components/CustomTable';
import { CustomTableProps } from 'components/CustomTable/props';
import NoDataPlaceholder from 'components/DiscoverPage/NoDataPlaceholder';
import Pill from 'components/Pill';
import { formatCreationWithIntl } from 'components/Premium/utils';
import { ProcessedReflection } from 'components/SkillAnalysis/props';
import { getSkillLabel } from 'components/SkillAnalysis/utils';
import classNames from 'styles/utils/classNames';
import { ALERT_THRESHOLD_HOURS } from '../constants';

const ReflectionTable: React.FC<ReflectionTableProps> = ({
  data,
  onReflect,
  onDetailBtnClick,
  isAllPlayersInfo,
  onEditReflection,
}) => {
  const columns: CustomTableProps<ProcessedReflection>['columns'] = [
    {
      title: 'Elapsed',
      columnClassName: 'w-[4rem]',
      render: (row) => {
        const differenceInHours = getDifferenceInHours(row.date);
        return (
          <div className="pl-4">
            {row.isCoachReflectionPending === false ? (
              <Check className="h-5 w-5 text-brand-green-300" />
            ) : differenceInHours > ALERT_THRESHOLD_HOURS ? (
              <>
                <Tooltip
                  className="!rounded-xl !bg-brand-fire-500"
                  id={`alert-icon-tip-${row.lessonId}`}
                  content={`This player has been waiting for more than ${Math.floor(
                    differenceInHours / 24,
                  )} days. Please provide feedback as quickly as possible!`}
                  place="top"
                />
                <AlertIcon
                  className="text-color-brand-primary"
                  data-tooltip-id={`alert-icon-tip-${row.lessonId}`}
                />
              </>
            ) : (
              <span className="typography-product-caption text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
                {differenceInHours}h
              </span>
            )}
          </div>
        );
      },
    },

    {
      title: 'Player',
      columnClassName: isAllPlayersInfo ? 'table-cell' : 'hidden',
      render: (row) => (
        <div className="flex items-center gap-1">
          <img
            className="h-8 w-8 rounded-full"
            src={row.playerAvatarInfo?.imageSrc || EMPTY_AVATAR_SRC}
            alt="avatar"
          />
          <span className="typography-product-chips-filters capitalize">
            {row.playerAvatarInfo?.name}
          </span>
        </div>
      ),
    },
    {
      title: 'Date',
      columnClassName: isAllPlayersInfo ? 'max-sm:hidden' : 'table-cell',
      render: (row) => (
        <span className="typography-product-chips-filters whitespace-nowrap text-color-text-lightmode-secondary dark:text-color-text-lightmode-secondary">
          {formatCreationWithIntl(row.date, false)}
        </span>
      ),
    },

    {
      title: 'Media',
      columnClassName: isAllPlayersInfo ? 'hidden' : 'max-sm:hidden',
      render: ({ media }) => {
        if (!media) {
          return (
            <NoDataPlaceholder
              text={<span className="typography-product-text-card-mobile">No Media</span>}
              className="!h-20 w-full"
            />
          );
        } else {
          if (media.format === FileFormatEnum.Video || media.format === FileFormatEnum.Audio) {
            return (
              <video
                controls
                className={classNames(
                  'w-28 rounded-xl object-cover',
                  media.format === FileFormatEnum.Audio ? 'h-10' : 'h-20',
                )}
              >
                <source src={media.src} />
                Your browser does not support the video tag.
              </video>
            );
          } else {
            return <img src={media.src} className="h-20 w-28 rounded-xl object-cover" />;
          }
        }
      },
    },

    {
      title: 'Skills',
      dataIndex: 'skills',
      columnClassName: isAllPlayersInfo ? 'hidden' : 'max-sm:hidden',
      render: (row) => (
        <div className="flex flex-wrap gap-1">
          {row.skills.slice(0, 3).map((skill, index) => (
            <Pill
              size="sm"
              className="text-center"
              key={`${skill}-${index}`}
              text={getSkillLabel(skill || '')}
            />
          ))}
        </div>
      ),
    },
    {
      title: '',
      render: (row) => {
        if (row.isCoachReflectionPending && row.lessonId) {
          return (
            <Button
              onClick={() => onReflect?.(row.lessonId, row.lessonOwner!, row.date, row.player)}
              variant="brand"
              sizeDesktop="sm"
              sizeMobile="xs"
              className="ml-auto w-full max-w-fit"
              iconLeft={<NoteIcon className="h-4 w-4 text-color-text-lightmode-invert" />}
            >
              Reflect
            </Button>
          );
        } else {
          return (
            <div className="ml-auto flex w-fit flex-col gap-2">
              {!row.isCoachReflectionPending && onEditReflection && (
                <Button
                  variant="primary"
                  onClick={() => onEditReflection(row.lessonId!)}
                  sizeDesktop="sm"
                  sizeMobile="xs"
                  iconLeft={<Pencil className="h-4 w-4" />}
                  className="w-fit"
                >
                  Edit
                </Button>
              )}
              {onDetailBtnClick && (
                <Button
                  onClick={() => onDetailBtnClick(row.lessonId!)}
                  variant="secondary"
                  sizeDesktop="sm"
                  sizeMobile="xs"
                  className="w-full max-w-fit"
                  iconLeft={<ArrowRight className="h-4 w-4" />}
                >
                  Review
                </Button>
              )}
            </div>
          );
        }
      },
    },
  ];

  return (
    <div>
      <h3 className="typography-product-heading-compact-desktop mb-4 text-color-text-lightmode-secondary dark:text-color-bg-darkmode-secondary">
        {isAllPlayersInfo ? 'Remaining Reflections' : 'Lessons Reflections'}
      </h3>
      <CustomTable columns={columns} data={data} />
    </div>
  );
};

export default ReflectionTable;
