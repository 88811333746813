import { StatCardProps } from 'screens/CoachPlayersTab/props';
import classNames from 'styles/utils/classNames';

const StatCard: React.FC<StatCardProps> = ({ icon, title, num, brand = false }) => {
  return (
    <div className="flex flex-1 justify-between gap-4 rounded-[1.5rem] border border-color-border-input-lightmode px-ds-xl py-ds-xl shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)] dark:border-color-border-input-darkmode">
      <span className="max-sm:hidden">{icon}</span>
      <div className="flex flex-col gap-ds-md">
        <span
          className={classNames(
            'typography-product-heading-compact-desktop',
            brand && 'text-color-text-brand',
          )}
        >
          {num}
        </span>
        <span
          className={classNames(
            'typography-product-caption mt-1  text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary',
            brand && '!text-color-text-brand',
          )}
        >
          {title}
        </span>
      </div>
    </div>
  );
};

export default StatCard;
