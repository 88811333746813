import classNames from 'styles/utils/classNames';
import { CustomTableProps } from './props';

const CustomTable = <T,>({
  columns,
  data,
  className = '',
  shadow = false,
  headerClassName,
}: CustomTableProps<T>) => {
  const borderClass = 'border-color-border-card-lightmode  dark:border-color-border-card-darkmode ';

  const shadowClass = 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]';

  return (
    <div
      className={classNames(
        'overflow-hidden rounded-2xl border',
        borderClass,
        shadow && shadowClass,
      )}
    >
      <table className={classNames('w-full table-fixed overflow-x-auto', className)}>
        <thead>
          <tr
            className={classNames(
              'typography-product-chips-filters overflow-hidden  bg-color-bg-lightmode-tertiary text-left text-color-text-lightmode-tertiary dark:bg-color-bg-darkmode-tertiary dark:text-color-text-darkmode-tertiary',
              headerClassName,
            )}
          >
            {columns.map((col, index) => (
              <th key={index} className={classNames('px-5 py-4 font-medium', col.columnClassName)}>
                {col.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} className={classNames('border-b last:border-b-0', borderClass)}>
              {columns.map((col, colIndex) => {
                const value = col.dataIndex ? row[col.dataIndex] : undefined;

                const renderContent = col.render
                  ? col.render(row, rowIndex)
                  : (value as React.ReactNode);

                return (
                  <td
                    key={colIndex}
                    className={classNames(
                      'bg-color-bg-lightmode-primary px-5 py-3 group-last:first:rounded-bl-2xl group-last:last:rounded-br-2xl dark:bg-color-bg-darkmode-primary',
                      col.columnClassName,
                    )}
                  >
                    {renderContent}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
