import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { EMPTY_COACH_AVATAR_SRC } from 'constants/user';
import ArrowNarrowDown from 'svg/ArrowNarrowDown';
import Pencil from 'svg/Pencil';
import { formatDate } from 'screens/TournamentDetails/utils';
import { ButtonText } from 'components/Button';
import Pill from 'components/Pill';
import classNames from 'styles/utils/classNames';
import { SkillsTrainProps } from './props';
import { getSkillLabel } from './utils';

const SkillsTrain: React.FC<SkillsTrainProps> = ({
  className,
  futureSkills,
  showAvatar = false,
  onEdit,
}) => {
  const [showingAll, setShowingAll] = useState(false);

  const slicedFutureSkills = useMemo(
    () => futureSkills?.slice(0, showingAll ? futureSkills.length : 1) || [],
    [showingAll, futureSkills],
  );

  return (
    <div
      className={classNames(
        'overflow-hidden rounded-[1.5rem] border border-color-border-card-lightmode  shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)] dark:border-color-border-card-darkmode',
        className,
      )}
    >
      <div className="flex items-center gap-2 p-4">
        <img src="/images/coaches/racket.png" alt="racket" className="h-11 w-11 object-contain" />
        <h3 className="typography-product-heading-compact-desktop text-color-brand-primary">
          Skills to train during next lesson
        </h3>
      </div>
      {slicedFutureSkills.map((futureSkill, index) => (
        <div className="mb-4 px-4" key={index}>
          {showAvatar && (
            <div className="flex items-center gap-2">
              <img
                src={futureSkill?.avatarInfo?.imageSrc || EMPTY_COACH_AVATAR_SRC}
                className="h-8 w-8 rounded-full object-cover object-top"
              />
              <span className="typography-product-caption ">{futureSkill?.avatarInfo?.name}</span>
            </div>
          )}
          <div className="mt-4 flex gap-2">
            {futureSkill.skills.map((skill) => (
              <Pill text={getSkillLabel(skill)} type="brand" key={skill} />
            ))}
          </div>
          <div className="mt-4 flex items-center justify-end gap-4">
            <p className="typography-product-text-card-mobile  text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
              noted on {formatDate({ date: futureSkill.notedDate })}
            </p>
            {onEdit && (
              <ButtonText onClick={() => onEdit(futureSkill)} className="flex items-center gap-1">
                <Pencil className="h-4 w-4" />
                <span>Edit</span>
              </ButtonText>
            )}
          </div>
        </div>
      ))}

      {futureSkills?.length > 1 && (
        <div className="flex justify-center border-t px-2 py-4">
          <ButtonText
            onClick={() => setShowingAll(!showingAll)}
            className="flex items-center gap-1"
          >
            <span>{showingAll ? 'Show less' : 'Show all'}</span>
            <span
              className={classNames('transition-transform', showingAll ? 'rotate-180' : 'rotate-0')}
            >
              <ArrowNarrowDown className="h-5 w-5 text-color-bg-lightmode-invert dark:text-color-bg-darkmode-invert" />
            </span>
          </ButtonText>
        </div>
      )}
    </div>
  );
};

export default SkillsTrain;
