import { add } from 'date-fns';
import { snakeCase, uniqBy } from 'lodash';
import { z } from 'zod';
import {
  GetCoachPremiumContentQuery,
  LessonSkillsEnum,
  SportsEnum,
  UserContentBetaSubscriptions,
  UserContentTiersEnum,
} from 'types/generated/client';

export const uploadContentSchema = z.object({
  title: z.string().min(1, 'Title is required').max(100, 'Title cannot exceed 100 characters'),
  description: z
    .string()
    .min(1, 'Description is required')
    .max(500, 'Description cannot exceed 500 characters'),
  skills: z.array(z.string()).min(1, 'At least one skill is required'),
  videoFile: z.any().refine((file) => file != null, {
    message: 'Video file is required',
  }),
  contentAccess: z.enum([UserContentTiersEnum.Premium, UserContentTiersEnum.Free]),
});

export const defaultUploadContentValues = {
  title: '',
  description: '',
  skills: [],
  videoFile: null,
  contentAccess: UserContentTiersEnum.Premium,
};

export const setPriceSchema = z.object({
  price: z
    .string()
    .min(1, 'Price is required')
    .regex(/^\d+(\.\d{1,2})?$/, 'Price must be a valid number')
    .transform((val) => (val === '' ? null : parseFloat(val)))
    .nullable()
    .refine((val) => val === null || val >= 0, {
      message: 'Price must be a positive number',
    }),
  description: z
    .string()
    .min(1, 'Description is required')
    .max(200, 'Description cannot exceed 200 characters'),
});

export const defaultSetPriceValues = {
  price: undefined,
  description: '',
};

export const contentAccessOptions = [
  { label: 'Subscription', value: UserContentTiersEnum.Premium },
  { label: 'Free', value: UserContentTiersEnum.Free },
];

export const toggleSportSelection = (sports: string[], sport: string): string[] => {
  if (sports.includes(sport)) {
    return sports.filter((item) => item !== sport);
  }
  return [...sports, sport];
};

export const getSports = (customSkills: Array<string> = []) => {
  const predefinedSports = [
    { label: 'Pickleball', value: SportsEnum.Pickleball },
    {
      label: 'Tennis',
      value: SportsEnum.Tennis,
    },
  ];

  const customSportsFormatted = customSkills.map((customSkill) => ({
    label: customSkill,
    value: snakeCase(customSkill).toUpperCase(),
  }));

  return uniqBy([...predefinedSports, ...customSportsFormatted], 'value');
};

export const separatePremiumSkillsByType = (
  skills: string[] = [],
  userId = '',
  fileId?: string,
) => {
  const defaultSkills: string[] = Object.values(LessonSkillsEnum) as string[];
  return skills.map((skill) => {
    const isDefaultSkill = defaultSkills.includes(skill);
    return {
      ...(fileId && { userContentFileId: fileId }),
      userContentSkills: {
        data: {
          skill: isDefaultSkill ? skill : null,
          customSkill: !isDefaultSkill ? skill : null,
          userId,
        },
      },
    };
  });
};

export const formatCreationWithIntl = (
  inputDate: string | Date,
  includeTime: boolean = true,
): string => {
  const date = new Date(inputDate);

  const options: Intl.DateTimeFormatOptions = includeTime
    ? {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }
    : {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const getVideoDuration = (videoUrl: string): Promise<string> => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.src = videoUrl;
    video.addEventListener('loadedmetadata', () => {
      const durationInSeconds = video.duration;
      const minutes = Math.floor(durationInSeconds / 60);
      const seconds = Math.floor(durationInSeconds % 60);
      resolve(`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`);
    });
    video.addEventListener('error', () => {
      resolve('N/A'); // Handle error cases
    });
  });
};

export const getVideoThumbnail = async (videoUrl: string) => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');

    video.src = videoUrl;
    video.crossOrigin = 'anonymous'; // Ensure proper CORS headers
    video.addEventListener('loadeddata', () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL('image/jpeg')); // Return thumbnail as base64 string
      } else {
        resolve(null); // Handle edge cases
      }
    });
    video.addEventListener('error', () => {
      resolve(null); // Handle error cases
    });
  });
};

export const getPlayerSubscribedUntil = (
  subscriptions: UserContentBetaSubscriptions[],
  viewerId: string,
) => {
  const subscribedUser = subscriptions?.find(
    ({ subscriberUserId }) => subscriberUserId === viewerId,
  );
  if (subscribedUser) {
    const subscribedDate = new Date(subscribedUser.createdAt);
    const subscribedUntilDate = add(subscribedDate, { years: 1 });
    return subscribedUntilDate;
  } else {
    return null;
  }
};

export const isLoggedInCoachPremiumContent = (
  content: any,
): content is GetCoachPremiumContentQuery => {
  return !!content?.userContentBetaSubscriptions;
};
