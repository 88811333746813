import { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { AccountType } from 'constants/user';
import { useModal } from 'hooks/useModal';
import ReflectionTable from 'screens/CoachPlayersTab/components/PlayerDetailsSection/OnCourt/ReflectionTable';
import ReflectionCard from './ReflectionCard';
import ReflectionDetailModal from './ReflectionDetailModal';
import { ReflectionDetailModalProps, ReflectionSectionProps } from './props';
import { getSortedByPendingReflection } from './utils';

const ReflectionCardMemoised = memo(ReflectionCard);

const ReflectionSection: React.FC<ReflectionSectionProps> = ({
  onEditReflection,
  reflections,
  onReflect,
  userType,
  initialSelectedLessonId,
  table = false,
  isAllPlayersInfo,
  showDivider = true,
}) => {
  const reflectionModal = useModal();

  const [selectedReflectionInfo, setSelectedReflectionInfo] = useState<
    ReflectionDetailModalProps['reflectionInfo'] | null
  >(null);

  const handleViewDetail = useCallback(
    (id: string) => {
      const reflection = reflections.find((reflection) => reflection.lessonId === id);
      if (!reflection) {
        return;
      }
      reflectionModal.openModal();
      setSelectedReflectionInfo({
        reflectionDetails: reflection.reflectionDetails,
        date: reflection.date,
        avatarInfo: reflection.coachAvatarInfo,
      });
    },
    [reflections],
  );

  const divider = (
    <div className="my-ds-3xl w-full border-t border-color-border-input-lightmode dark:border-color-border-input-darkmode" />
  );

  const getReflectionPending = (reflection: (typeof reflections)[0]) => {
    if (userType === AccountType.Coach) {
      return reflection.isCoachReflectionPending;
    } else {
      return reflection.isPlayerReflectionPending;
    }
  };

  const sortedByPendingReflection = useMemo(
    () => getSortedByPendingReflection(reflections, AccountType.Coach),
    [reflections],
  );

  useEffect(() => {
    if (initialSelectedLessonId && userType === AccountType.Player) {
      setTimeout(() => {
        handleViewDetail(initialSelectedLessonId);
      }, 1000); // a delay to show popup
    }
  }, [initialSelectedLessonId, userType, handleViewDetail]);

  return (
    <>
      <section className="flex flex-col">
        {showDivider && divider}
        {table ? (
          <ReflectionTable
            data={sortedByPendingReflection}
            onDetailBtnClick={handleViewDetail}
            onReflect={onReflect}
            isAllPlayersInfo={isAllPlayersInfo}
            onEditReflection={onEditReflection}
          />
        ) : (
          reflections.map((reflection, index) => (
            <Fragment key={reflection.lessonId}>
              <ReflectionCardMemoised
                isReflectionPending={getReflectionPending(reflection)}
                onDetailBtnClick={reflection.isCoachReflectionPending ? null : handleViewDetail}
                skills={reflection.skills}
                detail={reflection.detail}
                media={reflection.media}
                id={reflection.lessonId}
                key={reflection.lessonId}
                date={reflection.date}
                avatarInfo={userType === AccountType.Player ? reflection.coachAvatarInfo : null}
                onReflect={onReflect}
                lessonOwner={reflection.lessonOwner}
                onEditReflection={onEditReflection}
              />
              {index !== reflections.length - 1 && divider}
            </Fragment>
          ))
        )}
      </section>
      <ReflectionDetailModal
        isOpen={reflectionModal.isOpen}
        handleClose={reflectionModal.closeModal}
        reflectionInfo={selectedReflectionInfo}
        userType={userType}
      />
    </>
  );
};

export default ReflectionSection;
