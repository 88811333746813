import { differenceInHours, differenceInMinutes } from 'date-fns';
import { capitalize } from 'lodash';
import {
  FeedbackSentimentEnum,
  FollowStatusesEnum,
  GetCoachReflectionsBySubmitterIdQuery,
  GetCoachVideoAnalysisBySubmitterIdQuery,
  GetMyCoachFollowersPendingVideosQuery,
  GetPlayerReflectionByPlayerIdQuery,
  LessonReflections,
  LessonSkillsEnum,
  VideoAnalysisReviewStatusesEnum,
} from 'types/generated/client';
import { RemoteCoachTypesEnum } from 'utils/shared/coach/constants';
import Pill from 'components/Pill';
import { ReflectionFormType } from './components/ReflectFlowModal/props';
import { PlayerFiltersFormValues, PlayerType } from './props';

export const filterPlayers = (
  followersData: PlayerType[] | undefined,
  playerFormValues: PlayerFiltersFormValues,
) => {
  if (!followersData) {
    return [];
  }

  return followersData.filter((follow) => {
    const profile = follow.followerProfile;

    if (!profile || follow.status === FollowStatusesEnum.Inactive) {
      return false;
    }

    // Filter by player search text
    if (playerFormValues.playerSearchText && playerFormValues.playerSearchText.length >= 2) {
      const isSearched =
        profile.fullName?.toUpperCase().includes(playerFormValues.playerSearchText.toUpperCase()) ||
        profile.username?.toUpperCase().includes(playerFormValues.playerSearchText.toUpperCase());

      return isSearched;
    }

    return true;
  });
};

export const mapReflectionsToTable = (data: LessonReflections[]): TableRow[] => {
  let rowIndex = 1;
  const skillMap = new Map<
    string,
    {
      positive: number;
      neutral: number;
      critical: number;
      rowIndex: number;
      skill: string;
    }
  >();

  data.forEach((reflection) => {
    reflection.lessonReflectionComments.forEach((comment) => {
      const skill = comment.skill;
      const sentiment = comment.sentiment.toUpperCase();

      if (!skill) return;

      if (!skillMap.has(skill)) {
        skillMap.set(skill, {
          positive: 0,
          neutral: 0,
          critical: 0,
          rowIndex: rowIndex++,
          skill: skill,
        });
      }

      const skillData = skillMap.get(skill);
      if (skillData) {
        if (sentiment === FeedbackSentimentEnum.Positive) {
          skillData.positive++;
        } else if (sentiment === FeedbackSentimentEnum.Neutral) {
          skillData.neutral++;
        } else if (sentiment === FeedbackSentimentEnum.Critical) {
          skillData.critical++;
        }
      }
    });
  });

  const tableData: TableRow[] = Array.from(skillMap.values()).map((skillData) => {
    const { positive, neutral, critical, rowIndex, skill } = skillData;
    return {
      id: rowIndex,
      rowData: [
        <span className="typography-product-subheading">{rowIndex}</span>,
        <span className="typography-product-card-title-mobile">
          {capitalize(skill.replaceAll('_', ' '))}
        </span>,
        <span className="typography-product-subheading">{positive + neutral + critical}</span>,
      ],
      expandableContent: (
        <div className="flex pr-24 max-sm:pr-14">
          <div className="ml-auto flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <Pill text={positive.toString()} type="success" size="sm" />
              <span className="typography-product-caption text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Positive
              </span>
            </div>
            <div className="flex items-center gap-4">
              <Pill text={neutral.toString()} size="sm" />
              <span className="typography-product-caption text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Neutral
              </span>
            </div>
            <div className="flex items-center gap-4">
              <Pill text={critical.toString()} type="critical" size="sm" />
              <span className="typography-product-caption text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Critical
              </span>
            </div>
          </div>
        </div>
      ),
    };
  });

  return tableData;
};

export const mapVideoAnalysisToTable = (
  data: GetCoachVideoAnalysisBySubmitterIdQuery['videoAnalysis'],
): TableRow[] => {
  let rowIndex = 1;
  const skillMap = new Map<
    string,
    {
      positive: number;
      neutral: number;
      critical: number;
      rowIndex: number;
      skill: string;
    }
  >();

  data.forEach((video) => {
    video.videoAnalysisComments.forEach((comment) => {
      comment.videoAnalysisSkillsComments.forEach((skillComment) => {
        const skill = skillComment.videoLessonSkills.skill;

        if (!skill) return;
        const sentiment = comment.sentiment.toUpperCase();

        if (!skillMap.has(skill)) {
          skillMap.set(skill, {
            positive: 0,
            neutral: 0,
            critical: 0,
            rowIndex: rowIndex++,
            skill: skill,
          });
        }

        const skillData = skillMap.get(skill);
        if (skillData) {
          if (sentiment === FeedbackSentimentEnum.Positive) {
            skillData.positive++;
          } else if (sentiment === FeedbackSentimentEnum.Neutral) {
            skillData.neutral++;
          } else {
            skillData.critical++;
          }
        }
      });
    });
  });

  const tableData: TableRow[] = Array.from(skillMap.values()).map((skillData) => {
    const { positive, neutral, critical, rowIndex, skill } = skillData;
    return {
      id: rowIndex,
      rowData: [
        <span className={`typography-product-subheading`}>{rowIndex}</span>,
        <span className={`typography-product-card-title-mobile`}>
          {capitalize(skill?.replaceAll('_', ' '))}
        </span>,
        <span className={`typography-product-subheading`}>{positive + neutral + critical}</span>,
      ],
      expandableContent: (
        <div className="flex pr-24 max-sm:pr-14">
          <div className="ml-auto flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <Pill text={positive.toString()} type="success" size="sm" />
              <span className="typography-product-caption text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Positive
              </span>
            </div>
            <div className="flex items-center gap-4">
              <Pill text={neutral.toString()} size="sm" />
              <span className="typography-product-caption text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Neutral
              </span>
            </div>
            <div className="flex items-center gap-4">
              <Pill text={critical.toString()} type="critical" size="sm" />
              <span className="typography-product-caption text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Critical
              </span>
            </div>
          </div>
        </div>
      ),
    };
  });

  return tableData;
};

export const mergePendingActionsWithFollowers = (
  userFollows: PlayerType[],
  followerPendingVideos: GetMyCoachFollowersPendingVideosQuery['videoAnalysis'],
  lessonParticipants: GetMyCoachFollowersPendingVideosQuery['lessonParticipants'],
): PlayerType[] => {
  const mergedPlayers = userFollows.map((userFollow) => {
    const pendingVideoAnalysis = followerPendingVideos.find(
      (followerPendingVideo) => followerPendingVideo.submissionUserId === userFollow.followerUserId,
    );

    const pendingReflection = lessonParticipants.find(
      (lessonParticipant) =>
        lessonParticipant.userId === userFollow.followerUserId &&
        lessonParticipant.lesson.lessonReflections.length === 0,
    );

    let pendingAction = null;
    if (pendingVideoAnalysis && pendingReflection) {
      pendingAction =
        new Date(pendingVideoAnalysis.createdAt) > new Date(pendingReflection.lesson.createdAt)
          ? {
              lessonId: pendingVideoAnalysis.id,
              coachLessonType: RemoteCoachTypesEnum.Remote,
              createdAt: pendingVideoAnalysis.createdAt,
            }
          : {
              lessonId: pendingReflection.lesson.id,
              coachLessonType: RemoteCoachTypesEnum.Court,
              createdAt: pendingReflection.lesson.createdAt,
            };
    } else if (pendingVideoAnalysis) {
      pendingAction = {
        lessonId: pendingVideoAnalysis.id,
        coachLessonType: RemoteCoachTypesEnum.Remote,
        createdAt: pendingVideoAnalysis.createdAt,
      };
    } else if (pendingReflection) {
      pendingAction = {
        lessonDate: pendingReflection.lesson.createdAt,
        lessonId: pendingReflection.lesson.id,
        coachLessonType: RemoteCoachTypesEnum.Court,
        createdAt: pendingReflection.lesson.createdAt,
      };
    }

    return {
      ...userFollow,
      pendingAction,
    };
  });

  return mergedPlayers;
};

export const getTimeSpentOnCourt = (
  lessons:
    | GetCoachReflectionsBySubmitterIdQuery['lessons']
    | GetPlayerReflectionByPlayerIdQuery['lessons']
    | undefined,
): string => {
  if (!lessons || lessons.length === 0) return '0 h, 0 m';

  const totalMinutes = lessons.reduce((total, lesson) => {
    if (!lesson.times || lesson.times.length === 0) return total;

    const lessonMinutes = lesson.times.reduce((lessonTotal, time) => {
      const start = new Date(time.startDateTime);
      const end = new Date(time.endDateTime);

      if (isNaN(start.getTime()) || isNaN(end.getTime())) return lessonTotal;

      const durationMinutes = differenceInMinutes(end, start);
      return lessonTotal + durationMinutes;
    }, 0);

    return total + lessonMinutes;
  }, 0);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours} h, ${minutes} m`;
};

export const getFormattedCoachReflectionForFormToEdit = (
  lessonReflection: LessonReflections,
): ReflectionFormType => ({
  skills: lessonReflection.lessonReflectionComments.map(
    (lessonReflectionComment) =>
      lessonReflectionComment.skill || lessonReflectionComment.customSkill,
  ) as LessonSkillsEnum[],
  skillsRanking: lessonReflection?.lessonReflectionComments
    .toSorted((a, b) => a.order - b.order)
    .map(
      (sortedLessonReflectionComment) =>
        sortedLessonReflectionComment.skill || sortedLessonReflectionComment.customSkill,
    ) as LessonSkillsEnum[],
  feedback: lessonReflection.lessonReflectionComments.map((lessonReflectionComment) => ({
    sentiment: lessonReflectionComment.sentiment as FeedbackSentimentEnum,
    skill:
      (lessonReflectionComment.skill as LessonSkillsEnum) || lessonReflectionComment.customSkill,
    details: lessonReflectionComment.details || '',
    lessonReflectionFiles: lessonReflectionComment.lessonReflectionFiles?.[0],
  })),
});

export const getDifferenceInHours = (date: string) => {
  const hoursDifference = differenceInHours(new Date(), new Date(date));
  return hoursDifference;
};
