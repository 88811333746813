import { capitalize } from 'lodash';
import { EMPTY_AVATAR_SRC } from 'constants/user';
import { FeedbackSentimentEnum, FileFormatEnum } from 'types/generated/client';
import ArrowRight from 'svg/ArrowRight';
import Calendar from 'svg/Calendar';
import Pencil from 'svg/Pencil';
import { formatDate } from 'screens/TournamentDetails/utils';
import Button, { ButtonText } from 'components/Button';
import Pill from 'components/Pill';
import { PillType } from 'components/Pill/props';
import { sentimentMapper } from 'components/VideoAnalysis/utils';
import classNames from 'styles/utils/classNames';
import { ReflectionCardProps } from './props';
import { getSkillLabel } from './utils';

const ReflectionCard: React.FC<ReflectionCardProps> = ({
  skills,
  onDetailBtnClick,
  detail,
  sentiment,
  date,
  avatarInfo,
  media,
  id = null,
  onReflect,
  isReflectionPending = false,
  lessonOwner,
  onEditReflection,
}) => {
  return (
    <div className="flex flex-col gap-3" id={`reflection-${id}`}>
      <div className="flex items-center justify-between">
        <div className="flex items-start gap-2">
          {date && (
            <>
              <Calendar className="h-5 w-5 text-color-text-lightmode-tertiary" />
              <span
                className={
                  'typography-product-card-title text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary'
                }
              >
                {formatDate({ date })}
              </span>
            </>
          )}
        </div>

        {isReflectionPending && id && (
          <Button
            onClick={() => onReflect?.(id, lessonOwner!, date)}
            variant="brand"
            size="sm"
            className="w-fit"
          >
            Reflect
          </Button>
        )}
        {!isReflectionPending && onEditReflection && (
          <Button
            variant="primary"
            onClick={() => onEditReflection(id!)}
            size="sm"
            iconLeft={<Pencil className="h-4 w-4" />}
            className="w-fit"
          >
            Edit
          </Button>
        )}
      </div>

      {avatarInfo && (
        <div className="mt-1 flex items-center gap-3">
          <img
            src={avatarInfo.imageSrc || EMPTY_AVATAR_SRC}
            alt={'profile-image'}
            className="h-8 w-8 rounded-full"
          />
          <span className="typography-product-caption capitalize text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            {avatarInfo.name}
          </span>
        </div>
      )}

      {isReflectionPending === false && (
        <>
          <div className="flex flex-wrap gap-2">
            {Array.isArray(skills) ? (
              skills.map((skill, index) => <Pill text={getSkillLabel(skill)} key={index} />)
            ) : (
              <Pill text={getSkillLabel(skills)} key={'single'} type="brand" />
            )}
            {sentiment && (
              <Pill
                text={capitalize(sentiment)}
                type={sentimentMapper[sentiment as FeedbackSentimentEnum] as PillType}
              />
            )}
          </div>

          <p className="typography-product-body text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            {detail}
          </p>

          {media &&
            (media.format === FileFormatEnum.Video || media.format === FileFormatEnum.Audio) && (
              <video
                width="100%"
                controls
                className={classNames(
                  'mt-auto  rounded-xl object-contain',
                  media.format === FileFormatEnum.Audio ? 'h-10' : 'h-80',
                )}
              >
                <source src={media.src} />
                Your browser does not support the video tag.
              </video>
            )}

          {media && media.format === FileFormatEnum.Image && (
            <img src={media.src} className="mt-auto h-80 rounded-xl object-cover" />
          )}

          {onDetailBtnClick && (
            <div className="flex w-full justify-end">
              <ButtonText
                onClick={() => onDetailBtnClick(id!)}
                className="typography-product-button-label-large mt-1 inline-flex items-center gap-1"
              >
                Details <ArrowRight className="h-5 w-5" />
              </ButtonText>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ReflectionCard;
